import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.scss'
})

export class BookingComponent implements OnInit  {

  ngOnInit(): void {}
}
