import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingComponent } from './booking.component';
import { CoreModule } from '../../core/core.module';
import { RouterModule, Routes } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { BookingAddComponent } from './components/booking-add/booking-add.component';
import { BookingAssignComponent } from './components/booking-assign/booking-assign.component';
import { BookingCheckComponent } from './components/booking-check/booking-check.component';
import { BookingDetailComponent } from './components/booking-detail/booking-detail.component';
import { BookingFormComponent } from './components/booking-form/booking-form.component';
import { BookingHistoryComponent } from './components/booking-history/booking-history.component';
import { BookingEditComponent } from './components/booking-edit/booking-edit.component';
import { BookingTrackComponent } from './components/booking-track/booking-track.component';
import { BookingDetailTableComponent } from './components/booking-detail/components/booking-detail-table/booking-detail-table.component';
import { BookingTmRegisterComponent } from './components/booking-tm-register/booking-tm-register.component';
import { BookingTmRegisterAddServiceComponent } from './components/booking-tm-register/components/booking-tm-register-add-service/booking-tm-register-add-service.component';

const routes: Routes = [
  {
    path: 'all-bookings',
    component: BookingListComponent,
    data: { title: 'B.T.S. | All Bookings' }
  },
  {
    path: 'all-bookings/:status',
    component: BookingListComponent,
    data: { title: 'B.T.S. | All Bookings' }
  },
  {
    path: 'add-booking',
    component: BookingAddComponent,
    data: { title: 'B.T.S. | Add Booking' }
  },
  {
    path: 'edit-booking/:bookingcode',
    component: BookingEditComponent,
    data: { title: 'B.T.S. | Edit Booking' }
  },
  {
    path: 'booking-detail/:bookingcode',
    component: BookingDetailComponent,
    data: { title: 'B.T.S. | Booking Detail' }
  },
  {
    path: 'booking-tm-register/:bookingcode',
    component: BookingTmRegisterComponent,
    data: { title: 'B.T.S. | Booking TM Register' }
  }
];

@NgModule({
  declarations: [
    BookingComponent,
    BookingListComponent,
    BookingAddComponent,
    BookingAssignComponent,
    BookingCheckComponent,
    BookingDetailComponent,
    BookingFormComponent,
    BookingHistoryComponent,
    BookingEditComponent,
    BookingTrackComponent,
    BookingDetailTableComponent,
    BookingTmRegisterComponent,
    BookingTmRegisterAddServiceComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    ModalModule.forRoot(),
    BsDatepickerModule,
    NgxPaginationModule],
  exports: [
    RouterModule
  ]
})
export class BookingModule { }
