<app-header></app-header>
<div id="main-content">
    <div class="block-header">
        <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>Add Bookings Panel</h1>
                <span></span>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <a href="#" class="btn" [routerLink]="['/bookings/all-bookings']"><i class="fa fa-arrow-left"></i> Back to bookings list...</a>
            </div>
        </div>
    </div>
    <app-booking-form  [booking]="newBooking" [formAction]="action"></app-booking-form>
</div>