import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BookingModel } from '../../../../shared/models/booking.model';
import { ActivatedRoute } from '@angular/router';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';
import { BookingUtils } from '../../../../shared/utils/bookingUtils';
import { StatusCatalogEnum } from '../../../../shared/enums/status.catalog.enum';
import { BookingService } from '../../../../shared/services/booking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrl: './booking-detail.component.scss'
})
export class BookingDetailComponent {
  booking!: BookingModel;
  bookingCode!: string;
  bookingStatus: number = 0;
  userdata: any;
  ud: any;
  fechaSalida!: string;
  fechaRetorno!: string;
  numberOfDays!: number;
  externalBooking = new EventEmitter<BookingModel>();
  externalBookingCode = new EventEmitter<string>();
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  backRoute: string = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private generalUtils: GeneralUtils,
    private bookingUtils: BookingUtils,
    private bookingService: BookingService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    if (this.userdata.length == 0) {
      this.router.navigate(['']);
    } else {
      this.backRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/all-bookings';
      this.bookingCode = this.activatedRoute.snapshot.params['bookingcode'];
      this.bookingService.getBookingById(this.tokn, this.bookingCode).subscribe(
        result => {
          this.booking = result;
          this.booking.adultos = this.generalUtils.isNullOrUndefined(this.booking.adultos) ? 1 : this.booking.adultos;
          this.booking.ninos = this.generalUtils.isNullOrUndefined(this.booking.ninos) ? 0 : this.booking.ninos;
          this.externalBooking.emit(result);
          this.externalBookingCode.emit(this.bookingCode);
          this.fechaSalida = new Date(this.booking.fechaSalida).toDateString();
          this.fechaRetorno = new Date(this.booking.fechaRetorno).toDateString();
          this.numberOfDays = Math.floor((new Date(this.booking.fechaRetorno).getTime() - new Date(this.booking.fechaSalida).getTime()) / 1000 / 60 / 60 / 24);
        },
        error => {
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
    }
  }

  badgeTypeSet(badge: number): string {
    return this.bookingUtils.badgeTypeSet(badge);
  }

  statusCatalogClass(badge: number): string {
    return StatusCatalogEnum[badge];
  }

}
