import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class BookingUtils {
  badgeTypeSet(bookingStatus: number): string {
    let badgetype = "";
    switch (bookingStatus) {
      case 225: {
        badgetype = "info";
        break;
      }

      case 230: case 224: {
        badgetype = "default";
        break;
      }

      case 233: {
        badgetype = "success";
        break;
      }

      case 232: {
        badgetype = "danger";
        break;
      }

      case 227: case 228: case 229: {
        badgetype = "warning";
        break;
      }

      case 234: {
        badgetype = "primary";
        break;
      }

      case 231: {
        badgetype = "icon";
        break;
      }

      case 226: {
        badgetype = "secondary";
        break;
      }
    }
    return badgetype;
  }
}