import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CatalogModel } from '../../../../../../shared/models/catalog.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BookingTmRegisterServiceModel } from '../../../../../../shared/models/booking-tm-register-service.model';
import { GeneralUtils } from '../../../../../../shared/utils/generalUtils';

@Component({
  selector: 'app-booking-tm-register-add-service',
  templateUrl: './booking-tm-register-add-service.component.html',
  styleUrl: './booking-tm-register-add-service.component.scss'
})
export class BookingTmRegisterAddServiceComponent {
  //@Input() editedServiceId!:number; 
  @Input() externalService!: BookingTmRegisterServiceModel;
  @Output() closeModal = new EventEmitter<boolean>();
  //@Output() servicesUpdate = new EventEmitter<boolean>();
  @Output() managedService = new EventEmitter<BookingTmRegisterServiceModel>(); //reference

  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  saleType: any;
  submitted = false;
  saleTypeList!: CatalogModel[];
  st: any;
  locationList!: CatalogModel[];
  lc: any;
  hotelLocationList!: CatalogModel[];
  ht: any;
  cruiseDaysList!: CatalogModel[];
  cd: any;
  tourDaysList!: CatalogModel[];
  td: any;
  hotelNightsList!: CatalogModel[];
  hn: any;
  shipList!: CatalogModel[];
  sh: any;
  categoryList!: CatalogModel[];
  ct: any;
  backgroundButtonColor!: string;
  service!: BookingTmRegisterServiceModel;
  userdata:any;
  ud:any;
  startDateValue: string = "";
  endDateValue:string = "";
  minDateEnd!:Date;

  tmRegisterAddServiceForm: FormGroup = new FormGroup({
    saleType: new FormControl(),
    startDate: new FormControl(),
    endDate: new FormControl(),
    estimatedSale: new FormControl(),
    cruiseShip: new FormControl(),
    cruiseCategory: new FormControl(),
    cruiseDays: new FormControl(),
    lbtLocation: new FormControl(),
    lbtCategory: new FormControl(),
    lbtDays: new FormControl(),
    hotelLocation: new FormControl(),
    hotelNights: new FormControl(),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public generalUtils: GeneralUtils
  ) { }

  ngOnInit() {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    this.ct = sessionStorage.getItem('categoryList') || (localStorage.getItem('categoryList_' + this.ccode + '_' + this.ucode) || '');
    this.categoryList = JSON.parse(this.ct);
    this.st = sessionStorage.getItem('saleTypeList') || (localStorage.getItem('saleTypeList_' + this.ccode + '_' + this.ucode) || '');
    this.saleTypeList = JSON.parse(this.st);
    this.lc = sessionStorage.getItem('locationList') || (localStorage.getItem('locationList_' + this.ccode + '_' + this.ucode) || '');
    this.locationList = JSON.parse(this.lc);
    this.ht = sessionStorage.getItem('hotelLocationList') || (localStorage.getItem('hotelLocationList_' + this.ccode + '_' + this.ucode) || '');
    this.hotelLocationList = JSON.parse(this.ht);
    this.cd = sessionStorage.getItem('cruiseDaysList') || (localStorage.getItem('cruiseDaysList_' + this.ccode + '_' + this.ucode) || '');
    this.cruiseDaysList = JSON.parse(this.cd);
    this.td = sessionStorage.getItem('tourDaysList') || (localStorage.getItem('tourDaysList_' + this.ccode + '_' + this.ucode) || '');
    this.tourDaysList = JSON.parse(this.td);
    this.hn = sessionStorage.getItem('hotelNightsList') || (localStorage.getItem('hotelNightsList_' + this.ccode + '_' + this.ucode) || '');
    this.hotelNightsList = JSON.parse(this.hn);
    this.sh = sessionStorage.getItem('shipList') || (localStorage.getItem('shipList_' + this.ccode + '_' + this.ucode) || '');
    this.shipList = JSON.parse(this.sh);
    this.backgroundButtonColor = "background:" + (sessionStorage.getItem('modal-header-color') || (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || ''));
    this.service = this.externalService;
  }

  submitTmRegisterAddServiceForm() {
    this.submitted = true;
    if (!this.tmRegisterAddServiceForm.invalid) {
      // Create/Update service
      //this.servicesUpdate.emit(true);
      this.service.id = this.random();
      this.managedService.emit(this.service);
      this.closeModal.emit(true);
    } else {
      this.toastr.warning("Please check form warnings.", "Warning!");
    }
  }

  saleTypeChange() {
    //this.submitted = false;
    this.tmRegisterAddServiceForm.controls['cruiseShip'].reset();
    this.tmRegisterAddServiceForm.controls['cruiseShip'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['cruiseCategory'].reset();
    this.tmRegisterAddServiceForm.controls['cruiseCategory'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['cruiseDays'].reset();
    this.tmRegisterAddServiceForm.controls['cruiseDays'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['lbtLocation'].reset();
    this.tmRegisterAddServiceForm.controls['lbtLocation'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['lbtCategory'].reset();
    this.tmRegisterAddServiceForm.controls['lbtCategory'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['lbtDays'].reset();
    this.tmRegisterAddServiceForm.controls['lbtDays'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['hotelLocation'].reset();
    this.tmRegisterAddServiceForm.controls['hotelLocation'].setErrors(null);
    this.tmRegisterAddServiceForm.controls['hotelNights'].reset();
    this.tmRegisterAddServiceForm.controls['hotelNights'].setErrors(null);
    this.tmRegisterAddServiceForm.get('startDate')!.setValidators(Validators.required);
    this.tmRegisterAddServiceForm.get('endDate')!.setValidators(Validators.required);
    this.tmRegisterAddServiceForm.get('estimatedSale')!.setValidators(Validators.required);
    this.tmRegisterAddServiceForm.get('estimatedSale')!.setValidators(Validators.min(0));


    switch (this.service.saleType) {
      case 'cruise': {
        this.tmRegisterAddServiceForm.get('cruiseShip')!.setValidators(Validators.required);
        this.tmRegisterAddServiceForm.get('cruiseCategory')!.setValidators(Validators.required);
        this.tmRegisterAddServiceForm.get('cruiseDays')!.setValidators(Validators.required);
        break;
      }

      case 'lbt': {
        this.tmRegisterAddServiceForm.get('lbtLocation')!.setValidators(Validators.required);
        this.tmRegisterAddServiceForm.get('lbtCategory')!.setValidators(Validators.required);
        this.tmRegisterAddServiceForm.get('lbtDays')!.setValidators(Validators.required);
        break;
      }

      case 'hotel': {
        this.tmRegisterAddServiceForm.get('hotelLocation')!.setValidators(Validators.required);
        this.tmRegisterAddServiceForm.get('hotelNights')!.setValidators(Validators.required);
        break;
      }

      default: {
        break;
      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.tmRegisterAddServiceForm.controls;
  }

  onReset(): void {
    this.submitted = false;
    this.closeModal.emit(true);
  }

  random(): number {
    const minCeiled = Math.ceil(1);
    const maxFloored = Math.floor(50);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
  }

  formatStartDate(dateFrom: any) {
    this.minDateEnd = dateFrom;
    this.service.startDate= this.generalUtils.formatDateForService(dateFrom);
    this.service.endDate= this.generalUtils.formatDateForService(dateFrom);
    this.startDateValue = dateFrom;
  }

  formatEndDate(dateTo: any) {
    this.service.endDate = this.generalUtils.formatDateForService(dateTo);
  }

}
