import { Component, ViewChild, ViewContainerRef, ComponentRef, Input, TemplateRef } from '@angular/core';
import { BookingModel } from '../../../../shared/models/booking.model';
import { CatalogModel } from '../../../../shared/models/catalog.model';
import { CountryModel } from '../../../../shared/models/country.model';
import { UserModel } from '../../../../shared/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from '../../../../shared/services/booking.service';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { retry } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BookingTmRegisterAddServiceComponent } from './components/booking-tm-register-add-service/booking-tm-register-add-service.component';
import { BookingTmRegisterServiceModel } from '../../../../shared/models/booking-tm-register-service.model';

@Component({
  selector: 'app-booking-tm-register',
  templateUrl: './booking-tm-register.component.html',
  styleUrl: './booking-tm-register.component.scss'
})
export class BookingTmRegisterComponent {
  @ViewChild("serviceContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;
  @Input() externalEnable: boolean = false;

  modalRef!: BsModalRef;
  booking!: BookingModel;
  user!: UserModel;
  bookingCode!: string;
  userdata: any;
  ud: any; countryCode!: number;
  cityCode!: number;
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  backRoute: string = "";
  submitted = false;
  countriesList!: CountryModel[];
  cn: any;
  categoryList!: CatalogModel[];
  ct: any;
  saleTypeList!: CatalogModel[];
  st: any;
  locationList!: CatalogModel[];
  lc: any;
  hotelLocationList!: CatalogModel[];
  ht: any;
  cruiseDaysList!: CatalogModel[];
  cd: any;
  tourDaysList!: CatalogModel[];
  td: any;
  hotelNightsList!: CatalogModel[];
  hn: any;
  shipList!: CatalogModel[];
  sh: any;
  saleType: string = "";
  backgroundButtonColor!: string;
  primaryGradientModal!: string;
  isAddingService: boolean = false;
  totalEstimatedSale: number = 0;
  searchingServices: boolean = false;
  servicesList: BookingTmRegisterServiceModel[] = [];
  pageS: number = 1;
  selectedService!: BookingTmRegisterServiceModel;


  tmRegisterForm: FormGroup = new FormGroup({
    bsiName: new FormControl(),
    bsiNationality: new FormControl(),
    bsiAge: new FormControl(),
    bsiAdults: new FormControl(),
    bsiChildren: new FormControl(),
    bsiRegisterDate: new FormControl(),
    bsiCodeTM: new FormControl(),
    bsiEstimatedSale: new FormControl(),
  });

  constructor(
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
    private router: Router,
    public generalUtils: GeneralUtils,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    this.primaryGradientModal = "--primary-color:" + (sessionStorage.getItem('modal-header-color') || (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || '')) + " !important";

    if (this.userdata.length == 0) {
      this.router.navigate(['']);
    } else {
      this.backRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/all-bookings';
      this.bookingCode = this.activatedRoute.snapshot.params['bookingcode'];
      this.cn = sessionStorage.getItem('countriesList') || (localStorage.getItem('countriesList_' + this.ccode + '_' + this.ucode) || '');
      this.countriesList = JSON.parse(this.cn);
      this.bookingService.getBookingById(this.tokn, this.bookingCode).pipe(retry(2)).subscribe(
        result => {
          this.booking = result;
          console.log(this.booking);
          let todayPlus1 = new Date();
          todayPlus1.setDate(new Date().getDate() + 1);
          this.booking.fechaSalida = this.generalUtils.isNullOrUndefined(this.booking.fechaSalida) ? new Date().getTime() : this.booking.fechaSalida;
          this.booking.fechaRetorno = this.generalUtils.isNullOrUndefined(this.booking.fechaRetorno) ? todayPlus1.getTime() : this.booking.fechaRetorno;
          this.booking.adultos = this.generalUtils.isNullOrUndefined(this.booking.adultos) ? 1 : this.booking.adultos;
          this.booking.ninos = this.generalUtils.isNullOrUndefined(this.booking.ninos) ? 0 : this.booking.ninos;
          this.booking.categoria = this.generalUtils.isNullOrUndefined(this.booking.categoria) ? '0' : this.booking.categoria;
          this.booking.fuente = this.generalUtils.isNullOrUndefined(this.booking.fuente) ?
            '0' : this.booking.fuente;
          this.tmRegisterForm.get('bsiName')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiName')!.setValidators(Validators.minLength(3));
          this.tmRegisterForm.get('bsiName')!.setValidators(Validators.maxLength(30));
          this.tmRegisterForm.get('bsiNationality')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiAge')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiAge')!.setValidators(Validators.min(18));
          this.tmRegisterForm.get('bsiAdults')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiAdults')!.setValidators(Validators.min(1));
          this.tmRegisterForm.get('bsiAdults')!.setValidators(Validators.max(20));
          this.tmRegisterForm.get('bsiChildren')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiChildren')!.setValidators(Validators.max(20));
          this.tmRegisterForm.get('bsiRegisterDate')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiCodeTM')!.setValidators(Validators.required);
          this.tmRegisterForm.get('bsiEstimatedSale')!.setValidators(Validators.required)
        },
        error => {
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.tmRegisterForm.controls;
  }

  submitTmRegisterForm() {
    this.submitted = true;
    if (!this.tmRegisterForm.invalid) {
      //this.closeModal.emit(true);
      this.toastr.success("Status changed succesfully.", "Success!");
    } else {
      this.toastr.warning("Please check form warnings.", "Warning!");
    }
  }

  onReset(): void {
    this.submitted = false;
    this.router.navigate([this.backRoute]);
    //this.tmRegisterForm.reset();
  }

  ref!: ComponentRef<BookingTmRegisterAddServiceComponent>

  /** CHEQUEAR COMPORTAMIENTO AGREGANDO Y ELIMINANDO COMPONENTES */

  enablingAddServiceButton(enable: boolean) {
    this.isAddingService = enable;
  }

  pageChanged(e: any) {
    this.searchingServices = true;
    this.pageS = e;
    //this.listBookingsH();
  }

  openModal(template: TemplateRef<any>, serviceId: number): void {
    //this.selectedBookingCode = bookingId;
    if(serviceId!=0){
      this.selectedService = this.servicesList.filter(item => item.id === serviceId)[0];
    }else{
      this.selectedService = {
        id:0, //reference
        saleType: "",
        startDate: "",
        endDate: "",
        estimatedSale: 0,
        cruiseShip:0,
        cruiseCategory:0,
        landLocation:0,
        landTourCategory:0,
        hotelLocation:0,
        days:0,
        nights:0,
        status: ""
      }
    }
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' }),
    );
  }

  closingModal(closeModal: boolean) {
    if (closeModal) {
      this.modalRef.hide();
    }
  }

  reloadServicesTable(servicesUpdate: boolean) {
    if (servicesUpdate) {
      //add service
      console.log("updatingServices");
    }
  }

  reloadServices(managedService: BookingTmRegisterServiceModel) {
    let msId = managedService.id;
    let isNew = true;
    this.servicesList.forEach((service) => {
      if (msId == service.id) {
        isNew = false;
        service = managedService;
      }
    })
    if (isNew) {
      this.servicesList.push(managedService);
    }
    this.calculateTotalEstimatedSell();
  }

  deleteService() {
    let index = this.servicesList.findIndex(x => x.id === this.selectedService.id);
    if (index > -1) {
      this.servicesList.splice(index, 1);
    }
    this.calculateTotalEstimatedSell();
    this.closingModal(true);
  }

  calculateTotalEstimatedSell() {
    this.totalEstimatedSale = 0;
    this.servicesList.forEach((service) => {
      this.totalEstimatedSale += Number(service.estimatedSale);
    })
  }


}
