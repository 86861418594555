<form [formGroup]="tmRegisterAddServiceForm" (ngSubmit)="submitTmRegisterAddServiceForm()">
    <div class="row form-group">
        <div class="col-sm-3">
            <div class="form-group c_form_group">
                <label>Sale Type<span class="text-danger">*</span></label>
                <select class="form-control" [(ngModel)]="service.saleType" (ngModelChange)="saleTypeChange()"
                    formControlName="saleType" [ngClass]="{ 'is-invalid': submitted && f['saleType'].errors }"
                    required>
                    <option disabled selected [ngValue]="0">-Select-</option>
                    <option [ngValue]="item.valor" *ngFor="let item of saleTypeList">{{item.nombre}}
                    </option>
                </select>
                <div *ngIf="submitted && f['saleType'].errors" class="invalid-feedback">
                    <div *ngIf="f['saleType'].errors['required']">Sale Type
                        is required</div>
                </div>
            </div>
        </div>
        <div class="col-sm-9">
            <div class="row clearfix" *ngIf="service.saleType=='crucero'">
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Ship<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="cruiseShip"
                            [ngClass]="{ 'is-invalid': submitted && f['cruiseShip'].errors }" required 
                            [(ngModel)]="service.cruiseShip">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of shipList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['cruiseShip'].errors" class="invalid-feedback">
                            <div *ngIf="f['cruiseShip'].errors['required']">Ship
                                is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Cruise category<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="cruiseCategory"
                            [ngClass]="{ 'is-invalid': submitted && f['cruiseCategory'].errors }" required
                            [(ngModel)]="service.cruiseCategory">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of categoryList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['cruiseCategory'].errors" class="invalid-feedback">
                            <div *ngIf="f['cruiseCategory'].errors['required']">Cruise category
                                is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Days<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="cruiseDays"
                            [ngClass]="{ 'is-invalid': submitted && f['cruiseDays'].errors }" required 
                            [(ngModel)]="service.days">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of cruiseDaysList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['cruiseDays'].errors" class="invalid-feedback">
                            <div *ngIf="f['cruiseDays'].errors['required']">Status
                                is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix" *ngIf="service.saleType=='land'">
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Location<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="lbtLocation"
                            [ngClass]="{ 'is-invalid': submitted && f['lbtLocation'].errors }" required 
                            [(ngModel)]="service.landLocation">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of locationList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['lbtLocation'].errors" class="invalid-feedback">
                            <div *ngIf="f['lbtLocation'].errors['required']">Location
                                is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Tour Category<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="lbtCategory"
                            [ngClass]="{ 'is-invalid': submitted && f['lbtCategory'].errors }" required 
                            [(ngModel)]="service.landTourCategory">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of categoryList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['lbtCategory'].errors" class="invalid-feedback">
                            <div *ngIf="f['lbtCategory'].errors['required']">Tour Category
                                is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Days<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="lbtDays"
                            [ngClass]="{ 'is-invalid': submitted && f['lbtDays'].errors }" required 
                            [(ngModel)]="service.days">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of tourDaysList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['lbtDays'].errors" class="invalid-feedback">
                            <div *ngIf="f['lbtDays'].errors['required']">Days
                                is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix" *ngIf="service.saleType=='hotel'">
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Location<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="hotelLocation"
                            [ngClass]="{ 'is-invalid': submitted && f['hotelLocation'].errors }" required 
                            [(ngModel)]="service.hotelLocation">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of hotelLocationList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['hotelLocation'].errors" class="invalid-feedback">
                            <div *ngIf="f['hotelLocation'].errors['required']">Location
                                is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Nights<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="hotelNights"
                            [ngClass]="{ 'is-invalid': submitted && f['hotelNights'].errors }" required 
                            [(ngModel)]="service.nights">
                            <option disabled selected [ngValue]="0">-Select-</option>
                            <option [ngValue]="item.nombre" *ngFor="let item of hotelNightsList">
                                {{item.nombre}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['hotelNights'].errors" class="invalid-feedback">
                            <div *ngIf="f['hotelNights'].errors['required']">Nights
                                is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                </div>
            </div>
            <div class="row clearfix" *ngIf="service.saleType=='crucero'||service.saleType=='hotel'||service.saleType=='land'">
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Start Date<span class="text-danger">*</span></label>
                        <input type="text" data-provide="datepicker" data-date-autoclose="true"
                            class="form-control" placeholder="" bsDatepicker formControlName="startDate"
                            [ngClass]="{ 'is-invalid': submitted && f['startDate'].errors }"
                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}" 
                            required (bsValueChange)="formatStartDate($event)"  [(ngModel)]="service.startDate">
                        <div *ngIf="submitted && f['startDate'].errors" class="invalid-feedback">
                            <div *ngIf="f['startDate'].errors['required']">Start Date is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>End Date<span class="text-danger">*</span></label>
                        <input type="text" data-provide="datepicker" data-date-autoclose="true"
                            class="form-control" placeholder="" bsDatepicker formControlName="endDate"
                            [ngClass]="{ 'is-invalid': submitted && f['endDate'].errors }"
                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}" 
                            required (bsValueChange)="formatEndDate($event)"  [(ngModel)]="service.endDate" [minDate]="minDateEnd">
                        <div *ngIf="submitted && f['endDate'].errors" class="invalid-feedback">
                            <div *ngIf="f['endDate'].errors['required']">End Date is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group c_form_group">
                        <label>Estimated Sale<span class="text-danger">*</span></label>
                        <input class="form-control" type="number" maxlength="30" min="1" formControlName="estimatedSale"
                            [ngClass]="{ 'is-invalid': submitted && f['estimatedSale'].errors }" required [(ngModel)]="service.estimatedSale">
                        <div *ngIf="submitted && f['estimatedSale'].errors" class="invalid-feedback">
                            <div *ngIf="f['estimatedSale'].errors['required']">Estimated Sale is required</div>
                            <div *ngIf="f['estimatedSale'].errors['min']">Minimum value 1</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-12 text-lg-right">
        <button type="button" class="btn btn-default" (click)="onReset()">Cancel</button>
        <button type="submit" class="btn btn-primary" [attr.style]="backgroundButtonColor">Save</button>
    </div>
</form>
<hr/>