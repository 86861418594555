import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookingModel } from '../../../../shared/models/booking.model';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from '../../../../shared/services/booking.service';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-booking-check',
  templateUrl: './booking-check.component.html',
  styleUrl: './booking-check.component.scss'
})
export class BookingCheckComponent {

  @Input() bookingId!: string;
  @Input() externalBooking!: BookingModel;
  @Input() isModal!: Boolean;
  @Input() eventReceptor!: EventEmitter<BookingModel>;
  booking!: BookingModel;
  fechaSalida!: string;
  fechaRetorno!: string;
  numberOfDays!: number;
  ccode:string="";
  ucode:string="";
  tokn:string="";

  constructor(
    private toastr: ToastrService,
    private bookingService: BookingService,
    private generalUtils: GeneralUtils,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    if (!this.generalUtils.isNullOrUndefined(this.bookingId)) {
      this.bookingService.getBookingById(this.tokn, this.bookingId).subscribe(
        result => {
          this.booking = result;
          this.fechaSalida = new Date(this.booking.fechaSalida).toDateString();
          this.fechaRetorno = new Date(this.booking.fechaRetorno).toDateString();
          this.numberOfDays = Math.floor((new Date(this.booking.fechaRetorno).getTime() - new Date(this.booking.fechaSalida).getTime()) / 1000 / 60 / 60 / 24);
          this.booking.adultos = this.generalUtils.isNullOrUndefined(this.booking.adultos) ? 1 : this.booking.adultos;
          this.booking.ninos = this.generalUtils.isNullOrUndefined(this.booking.ninos) ? 0 : this.booking.ninos;
        },
        error => {
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
    } else {
      this.eventReceptor.subscribe((externalBooking: BookingModel) => {
        this.booking = externalBooking;
      });
    }
  }

}
