<app-booking-history [bookingId]="bookingId" [email]="email"></app-booking-history>
<form [formGroup]="bookingAssignForm" (ngSubmit)="submit()">
    <div class="col-sm-12">
        <label>Seller</label>
        <select class="form-control show-tick">
        <option [ngValue]="item.codigo" *ngFor="let item of users">
            {{item.nombres}} {{item.apellidos}}
        </option>
    </select>
    </div>
    <br >
    <div class="col-sm-12">
        <label>Observations</label>
        <div class="form-group c_form_group">
            <textarea class="form-control" value=""></textarea>
        </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 text-lg-right">
        <button class="btn btn-outline-secondary ml-1" (click)="onReset()">Cancel</button>
        <button type="submit" class="btn btn-primary" [attr.style]="backgroundButtonColor">Assign</button>
    </div>
</form>
