<div class="col-sm-12 modal-header">
    <div class="row clearfix">
        <h4 class="modal-title pull-left">Details</h4>
    </div>
</div>
<br>
<div class="col-sm-12">
    <div class="table-responsive">
        <table class="table table-striped custom-table">
            <thead>
                <tr style="border: 0px !important;">
                    <th>User</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Observations</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="totalBookingDetails==0" class="theme-bg gradient" style="text-align: center;color: white;"><td colspan="4">No booking details found.</td></tr>
                <tr *ngFor="let item of bookingDetails | paginate: { itemsPerPage: 5, currentPage: page }">
                    <td>{{item.usuario}}</td>
                    <td>{{item.fecha}}</td>
                    <td><span class="badge badge-success">{{item.status}}</span></td>
                    <td style="word-break: break-all;white-space: normal;">{{item.observaciones}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card">
        <nav aria-label="Page navigation example">
            <pagination-controls class="custom-pagination" (pageChange)="page = $event"></pagination-controls>
        </nav>
    </div>
</div>