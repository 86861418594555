export enum StatusCatalogEnum {
    'Por Asignar'=224,
	'Nuevo'=225,
	'En Contacto'=226,
	'Follow Up 1'=227,
	'Follow Up 2'=228,
	'Follow Up 3'=229,
	'Informativo'=230,
	'Spam'=231,
	'Muerto'=232,
	'Vendido'=233,
    'Repetido'=234
}