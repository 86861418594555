
<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Basic Information</h2>
                    </div>
                    <div class="body">
                        <div class="row clearfix">
                            <div class="col-sm-3">
                                <div class="form-group c_form_group">
                                    <label>Name<span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" maxlength="15">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group c_form_group">
                                    <label>Last name</label>
                                    <input class="form-control" type="text" maxlength="15">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group c_form_group">
                                    <label>E-mail</label>
                                    <input class="form-control" type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>