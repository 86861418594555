<app-header></app-header>
<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <h1>All Bookings Panel</h1>
                    <span></span>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                    <div class="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-400"
                        *ngIf="initialTotalBookings>0">
                        <div class="border-right pr-4 mr-4 mb-2 mb-xl-0">
                            <p class="text-muted mb-1">Total</p>
                            <h5 class="mb-0">{{ initialTotalBookings }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <form (keyup.enter)="countBookings()">
                        <input type="text" class="form-control" placeholder="Search Booking, Name, E-mail"
                            [(ngModel)]="searchTerm" name="searchTerm">
                    </form>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12">
                    <div class="input-group">
                        <input type="text" data-provide="datepicker" data-date-autoclose="true" class="form-control"
                            placeholder="Date from:"
                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}" bsDatepicker
                            (bsValueChange)="formatDateFrom($event)" [(ngModel)]="dateFromValue">
                    </div>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12">
                    <div class="input-group">
                        <input type="text" data-provide="datepicker" data-date-autoclose="true" class="form-control"
                            placeholder="Date to:"
                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}" bsDatepicker
                            (bsValueChange)="formatDateTo($event)" [(ngModel)]="dateToValue" [minDate]="minDateTo">
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="input-group">
                        <select class="form-control" [(ngModel)]="searchStatus">
                            <option (value)="'All'">All</option>
                            <option [ngValue]="item.valor" *ngFor="let item of bookingStatusList">{{item.nombre}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-1 col-md-12 col-sm-12">
                    <a href="javascript:void(0);" class="btn btn-default list-button"
                        (click)="countBookings()">Search</a>
                    <a href="javascript:void(0);" class="btn btn-default list-button" (click)="clearFilters()">Clear</a>
                </div>
            </div>
        </div>
        <br>
        <div class="row clearfix">
            <div class="col-sm-12">
                <div class="card">
                    <div class="table-responsive">
                        <table class="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th *ngIf="userdata.rolCodigo==4"><b>User</b></th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Booking</b></a><a (click)="sortBy('booking')"
                                            href="javascript:void(0)"><i class="fa fa-sort sort-button sort"></i></a>
                                    </th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Name</b></a><a (click)="sortBy('booking')"
                                            href="javascript:void(0)"><i class="fa fa-sort sort-button sort"></i></a>
                                    </th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Email</b></a><a (click)="sortBy('booking')"
                                            href="javascript:void(0)"><i class="fa fa-sort sort-button sort"></i></a>
                                    </th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Source</b></a><a (click)="sortBy('booking')"
                                            href="javascript:void(0)"><i class="fa fa-sort sort-button sort"></i></a>
                                    </th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Register
                                                Date</b></a><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><i class="fa fa-sort sort-button sort"></i></a>
                                    </th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Assignment
                                                Date</b></a><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><i class="fa fa-sort sort-button sort"></i></a>
                                    </th>
                                    <th><a class="sort" (click)="sortBy('booking')"
                                            href="javascript:void(0)"><b>Status</b></a><a class="sort"
                                            (click)="sortBy('booking')" href="javascript:void(0)"><i
                                                class="fa fa-sort sort-button sort"></i></a></th>
                                    <th><b>Options</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="searchingBooking" class="theme-bg gradient"
                                    style="text-align: center;color: white;">
                                    <td colspan="9">
                                        <strong>Loading...</strong>
                                        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </td>
                                </tr>
                                <tr *ngIf="actualTotalBookings==0 && !searchingBooking" class="theme-bg gradient"
                                    style="text-align: center;color: white;">
                                    <td colspan="9">No bookings were found according to the criteria, please try again.
                                    </td>
                                </tr>
                                <tr *ngFor="let item of bookings | paginate: { id: 'nav-list', itemsPerPage: pageSize, currentPage: page, totalItems: totalBookings }"
                                    [ngStyle]="{'display': searchingBooking ? 'none':'', 'background-color': isForAssignment(item.estado)? 'gainsboro':'white','font-weight': isForAssignment(item.estado) ? 'bolder':''}">
                                    <td *ngIf="userdata.rolCodigo==4" class="w60">
                                        <div class="circle">
                                            <button *ngIf="item.usuario==null" type="button"
                                                class="btn btn-default btn-sm ml-1" title="Assign"
                                                (click)="openModal(assign,item.codigo,item.email)"><i
                                                    class="fa fa-user"></i></button>
                                            <img *ngIf="item.usuario!=null && getProfilePath(item.usuario.foto).length != 0"
                                                class="rounded-circle avatar"
                                                [src]="'assets/images/sellers/'+item.usuario.foto" alt=""
                                                title="{{item.usuario.nombres}} {{item.usuario.apellidos}}">
                                            <div class="rounded-circle user-photo empty-img theme-bg gradient"
                                                *ngIf="item.usuario!=null && getProfilePath(item.usuario.foto).length == 0"
                                                title="{{item.usuario.nombres}} {{item.usuario.apellidos}}">
                                                <span>
                                                    {{firstLetter(item.usuario.nombres)}}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{item.codigo}}</td>
                                    <td>
                                        <div>{{item.nombres}} {{item.apellidos}}</div>
                                    </td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.fuente}}</td>
                                    <td>{{item.fechaRegistro}}</td>
                                    <td>{{item.fechaAsignacion}}</td>
                                    <td><span class="badge badge-{{setBadgeType(item.estado)}}">{{item.estado}}</span>
                                    </td>
                                    <td>
                                        <button *ngIf="userdata.rolCodigo==4" type="button"
                                            class="btn btn-default btn-sm ml-1" title="Assign"
                                            (click)="openModal(assign,item.codigo,item.email)"><i
                                                class="fa fa-user"></i></button>
                                        <button *ngIf="userdata.rolCodigo==4" type="button"
                                            class="btn btn-default btn-sm ml-1" title="Edit"
                                            [routerLink]="[editBookingRoute,item.codigo]"><i
                                                class="fa fa-pencil"></i></button>
                                        <button *ngIf="userdata.rolCodigo==4" type="button"
                                            class="btn btn-default btn-sm ml-1" title="Delete"
                                            (click)="openModal(delete,item.codigo,'')"><i
                                                class="fa fa-trash"></i></button>
                                        <button type="button" class="btn btn-default btn-sm ml-1" title="Detail"
                                            [routerLink]="[detailBookingRoute,item.codigo]"><i
                                                class="fa fa-calendar"></i></button>
                                        <button type="button" class="btn btn-default btn-sm ml-1" title="Check"
                                            (click)="openModal(check,item.codigo,'')"><i class="fa fa-eye"></i></button>
                                        <button type="button" class="btn btn-default btn-sm ml-1" title="Track booking"
                                            (click)="openModal(track,item.codigo,'')"
                                            *ngIf="showCheckButton(item.estado)"><i class="fa fa-gear"></i></button>
                                        <button type="button" class="btn btn-default btn-sm ml-1" title="TM Register"
                                            [routerLink]="[tmBookingRoute,item.codigo]"
                                            *ngIf="!showCheckButton(item.estado)"><i
                                                class="fa fa-credit-card"></i></button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <nav aria-label="Navigation list">
                        <pagination-controls id="nav-list" class="custom-pagination" (pageChange)="pageChanged($event)"
                            [responsive]="true" previousLabel="Prev">
                        </pagination-controls>
                    </nav>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12">
                    Items per Page:
                    <select [(ngModel)]="pageSize" (ngModelChange)="itemsPerPageChanged()">
                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                            {{ size }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #delete>
    <div class="modal-header theme-bg card" [attr.style]="primaryGradientModal">
        <h4 class="modal-title pull-left">Delete Booking<br /> {{selectedBookingCode}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row clearfix">
            <div class="col-lg-12">
                <span aria-hidden="true">Are you sure you want to delete this registry?</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-round btn-default" (click)="modalRef.hide()">No</button>
        <button type="button" class="btn btn-round btn-primary" style="background-color: #6cb440;"
            (click)="deleteBooking()">Yes</button>
    </div>
</ng-template>

<ng-template #check>
    <div class="modal-header theme-bg card" [attr.style]="primaryGradientModal">
        <h4 class="modal-title pull-left">Check Booking<br /> {{selectedBookingCode}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-booking-check [isModal]="true" [bookingId]="selectedBookingCode"></app-booking-check>
    </div>
</ng-template>

<ng-template #assign>
    <div class="modal-header theme-bg card" [attr.style]="primaryGradientModal">
        <h4 class="modal-title pull-left">Assign Seller</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-booking-assign [bookingId]="selectedBookingCode" [email]="selectedBookingEmail"
            (closeModal)="closingModal($event)"></app-booking-assign>
    </div>
</ng-template>

<ng-template #track>
    <div class="modal-header theme-bg card" [attr.style]="primaryGradientModal">
        <h4 class="modal-title pull-left">Track booking<br /> {{selectedBookingCode}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-booking-track (closeModal)="closingModal($event)" [bookingCode]="selectedBookingCode"></app-booking-track>
    </div>
</ng-template>

<!-- Bootstrap DatePicker -->
<script type="text/javascript">
    $(function () {
        $('#txtDate').datepicker({
            format: "dd/mm/yyyy"
        });
    });
</script>