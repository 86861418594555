import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingModel } from '../../../../shared/models/booking.model';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';
import { BookingService } from '../../../../shared/services/booking.service';
import { UserService } from '../../../../shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BookingUtils } from '../../../../shared/utils/bookingUtils';
import { StatusCatalogEnum } from '../../../../shared/enums/status.catalog.enum';
import { UserModel } from '../../../../shared/models/user.model';
import { CatalogService } from '../../../../shared/services/catalog.service';
import { UtilImage } from '../../../../shared/utils/utilImage';

@Component({
  selector: 'app-booking-edit',
  templateUrl: './booking-edit.component.html',
  styleUrl: './booking-edit.component.scss'
})
export class BookingEditComponent {
  booking!: BookingModel;
  user!: UserModel;
  bookingCode!: string;
  userdata: any;
  ud: any;
  statusCatalogEnum!: StatusCatalogEnum;
  countryCode!: number;
  cityCode!: number;
  externalBookingCode = new EventEmitter<string>();
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  backRoute: string = "";

  constructor(
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
    private userService: UserService,
    private router: Router,
    private bookingUtils: BookingUtils,
    public generalUtils: GeneralUtils,
    public utilImage:UtilImage
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    if (this.userdata.length == 0) {
      this.router.navigate(['']);
    } else {
      this.backRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/all-bookings';
      this.bookingCode = this.activatedRoute.snapshot.params['bookingcode'];
      this.bookingService.getBookingById(this.tokn, this.bookingCode).pipe(retry(2)).subscribe(
        result => {
          this.booking = result;
          console.log(this.booking);
          let todayPlus1 = new Date();
          todayPlus1.setDate(new Date().getDate() + 1);
          this.booking.fechaSalida = this.generalUtils.isNullOrUndefined(this.booking.fechaSalida) ? new Date().getTime() : this.booking.fechaSalida;
          this.booking.fechaRetorno = this.generalUtils.isNullOrUndefined(this.booking.fechaRetorno) ? todayPlus1.getTime() : this.booking.fechaRetorno;
          this.booking.adultos = this.generalUtils.isNullOrUndefined(this.booking.adultos) ? 1 : this.booking.adultos;
          this.booking.ninos = this.generalUtils.isNullOrUndefined(this.booking.ninos) ? 0 : this.booking.ninos;
          this.booking.categoria = this.generalUtils.isNullOrUndefined(this.booking.categoria) ? '0' : this.booking.categoria;
          this.booking.fuente = this.generalUtils.isNullOrUndefined(this.booking.fuente) ?
            '0' : this.booking.fuente;
          /*this.countryCode = this.bookingUtils.isNullOrUndefined(this.booking.pais) || this.booking.pais.length==0 ?
            0 : this.booking.pais;*/
          /*this.booking.ciudad = this.bookingUtils.isNullOrUndefined(this.booking.ciudad) || this.booking.pais.length==0 ?
            '0' : this.booking.ciudad;*/
          this.booking.pais = 55;
          this.booking.ciudad = 811;
          this.userService.getUserById(this.tokn, this.booking.usuarioCodigo.toString())
            .pipe(retry(2))
            .subscribe(
              result => {
                this.user = result;
                this.externalBookingCode.emit(this.activatedRoute.snapshot.params['bookingcode']);
              },
              error => {
                this.toastr.error(error.message);
              });
        },
        error => {
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
    }

  }

  badgeTypeSet(badge: number): string {
    return this.bookingUtils.badgeTypeSet(badge);
  }

  statusCatalogClass(badge: number): string {
    return StatusCatalogEnum[badge];
  }

  getProfilePath(photValue: string|null): string {
    return this.utilImage.getProfileImagePath(photValue);
  }

  firstLetter(name:string):string{
    return name.substring(0,1).toUpperCase();
  }

}
