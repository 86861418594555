<app-header></app-header>
<div id="main-content">
    <div class="block-header">
        <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>Booking TM Register Panel</h1>
                <span></span>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <a href="#" class="btn" [routerLink]="[backRoute]"><i class="fa fa-arrow-left"></i> Back to bookings
                    list...</a>
            </div>
        </div>
    </div>
    <div class="col-sm-12 modal-header card theme-bg gradient">
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 text-lg-right">
                <h1>Booking {{bookingCode}}</h1>
            </div>
        </div>
    </div>
    <div class="row clearfix">
        <div class="col-sm-12">
            <div class="col-sm-12 form-group c_form_group">
                <div class="row">
                    <div class="col-sm-4">
                        <div>
                            <small class="text-muted">Name:</small>
                            <p>{{booking.nombres}} {{booking.apellidos}}</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div>
                            <small class="text-muted">Email:</small>
                            <p style="word-break: break-all;white-space: normal;">{{booking.email}}</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div>
                            <small class="text-muted">Category:</small>
                            <p>{{booking.categoria}}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div>
                            <small class="text-muted"># Pax:</small>
                            <p>{{booking.ninos+booking.adultos}}</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div>
                            <small class="text-muted">Source:</small>
                            <p style="word-break: break-all;white-space: normal;">{{booking.fuente}}</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                    </div>
                </div>
            </div>
            <br />
            <div class="card">
                <form [formGroup]="tmRegisterForm" (ngSubmit)="submitTmRegisterForm()">
                    <br>
                    <div class="row clearfix">
                        <div class="col-sm-12">
                            <h4 class="modal-title pull-left">Booking sell information</h4>
                        </div>
                    </div>
                    <br>
                    <div class="row clearfix">
                        <div class="col-sm-6">
                            <div class="form-group c_form_group">
                                <label>Name<span class="text-danger">*</span></label>
                                <input class="form-control" type="text" maxlength="30" formControlName="bsiName"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiName'].errors }" required>
                                <div *ngIf="submitted && f['bsiName'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiName'].errors['required']">Name is required</div>
                                    <div *ngIf="f['bsiName'].errors['minlength']">
                                        Name must be at least 3 characters
                                    </div>
                                    <div *ngIf="f['bsiName'].errors['maxlength']">
                                        Name must not exceed 30 characters
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Nationality<span class="text-danger">*</span></label>
                                <select class="form-control" formControlName="bsiNationality"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiNationality'].errors }" required>
                                    <option disabled selected [ngValue]="0">-Select-</option>
                                    <option [ngValue]="item.nombre" *ngFor="let item of countriesList">{{item.nombre}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['bsiNationality'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiNationality'].errors['required']">Nationality
                                        is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Age<span class="text-danger">*</span></label>
                                <input class="form-control" type="number" formControlName="bsiAge" min="1" max="20"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiAge'].errors }" required>
                                <div *ngIf="submitted && f['bsiAge'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiAge'].errors['required']">Age is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Adults<span class="text-danger">*</span></label>
                                <input class="form-control" type="number" min="1" max="20" formControlName="bsiAdults"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiAdults'].errors }" required>
                                <div *ngIf="submitted && f['bsiAdults'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiAdults'].errors['required']">Adults
                                        is required</div>
                                    <div *ngIf="f['bsiAdults'].errors['min']">Minimum value 1</div>
                                    <div *ngIf="f['bsiAdults'].errors['max']">Max value 20</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Children<span class="text-danger">*</span></label>
                                <input class="form-control" type="number" min="0" max="20" formControlName="bsiChildren"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiChildren'].errors }" required>
                                <div *ngIf="submitted && f['bsiChildren'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiChildren'].errors['required']">Children
                                        is required</div>
                                    <div *ngIf="f['bsiChildren'].errors['max']">Max value 20</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Register Date<span class="text-danger">*</span></label>
                                <input type="text" data-provide="datepicker" data-date-autoclose="true"
                                    class="form-control" placeholder="" bsDatepicker formControlName="bsiRegisterDate"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiRegisterDate'].errors }"
                                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}" required>
                                <div *ngIf="submitted && f['bsiRegisterDate'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiRegisterDate'].errors['required']">Register Date is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Code TM<span class="text-danger">*</span></label>
                                <input class="form-control" type="text" maxlength="30" formControlName="bsiCodeTM"
                                    [ngClass]="{ 'is-invalid': submitted && f['bsiCodeTM'].errors }" required>
                                <div *ngIf="submitted && f['bsiCodeTM'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bsiCodeTM'].errors['required']">Code TM is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row clearfix">
                        <div class="col-sm-9 text-lg-right">
                            <h4 class="modal-title pull-left">Sold Services</h4>
                        </div>
                        <div class="col-sm-3 text-lg-right">
                            <button class="btn btn-primary" (click)="openModal(saleTypeModal,0)"
                                [attr.style]="backgroundButtonColor" type="button">Add Service</button>
                        </div>
                    </div>
                    <br>
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th>Service Type</th>
                                        <th>Description</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Estimated Sale</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="searchingServices" [attr.style]="primaryGradientModal"
                                        class="theme-bg gradient">
                                        <td colspan="9" style="text-align: center;color: white;">
                                            <strong>Loading...</strong>
                                            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="servicesList.length==0 && !searchingServices"
                                        [attr.style]="primaryGradientModal" class="theme-bg gradient">
                                        <td style="text-align: center;color: white;" colspan="9">No services were found
                                            according to
                                            the criteria, please try again.
                                        </td>
                                    </tr>
                                    <tr *ngFor="let item of servicesList | paginate: { id: 'nav-history', itemsPerPage: 5, currentPage: pageS, totalItems: servicesList.length}"
                                        [ngStyle]="{'display': searchingServices ? 'none':''}">
                                        <td>{{item.saleType}}</td>
                                        <td *ngIf="item.saleType=='land'">
                                            <span>
                                                Location: {{item.landLocation}}<br />
                                                Tour Category: {{item.landTourCategory}}<br />
                                                Days: {{item.days}}
                                            </span>
                                        </td>
                                        <td *ngIf="item.saleType=='hotel'">
                                            <span>
                                                Location: {{item.hotelLocation}}<br />
                                                Nights: {{item.nights}}
                                            </span>
                                        </td>
                                        <td *ngIf="item.saleType=='crucero'">
                                            <span>
                                                Ship: {{item.cruiseShip}}<br />
                                                Cruise Category: {{item.cruiseCategory}}<br />
                                                Days: {{item.days}}
                                            </span>
                                        </td>

                                        <td>{{item.startDate | date:'dd/MM/yyyy'}}</td>
                                        <td>{{item.endDate | date:'dd/MM/yyyy'}}</td>
                                        <td>{{item.estimatedSale}}</td>
                                        <td>
                                            <button *ngIf="userdata.rolCodigo==4" type="button"
                                                class="btn btn-default btn-sm ml-1" title="Edit"
                                                (click)="openModal(saleTypeModal,item.id)"><i
                                                    class="fa fa-pencil"></i></button>
                                            <button *ngIf="userdata.rolCodigo==4" type="button"
                                                class="btn btn-default btn-sm ml-1" title="Delete"
                                                (click)="openModal(delete,item.id)"><i class="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card">
                            <nav aria-label="Navigation history">
                                <pagination-controls id="nav-history" class="custom-pagination"
                                    (pageChange)="pageChanged($event)" [responsive]="true" previousLabel="Prev">
                                </pagination-controls>
                            </nav>
                        </div>
                    </div>
                    <br />
                    <div class="row clearfix">
                        <div class="col-sm-9 text-lg-right"></div>
                        <div class="col-sm-3 text-lg-right">
                            <label><strong>Total estimated sale: </strong>{{totalEstimatedSale}}</label>
                        </div>
                    </div>
                    <br />
                    <div class="col-sm-12 text-lg-right">
                        <button type="button" class="btn btn-default" (click)="onReset()">Cancel</button>
                        <button type="submit" class="btn btn-primary"
                            [attr.style]="backgroundButtonColor">Accept</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #saleTypeModal>
    <div class="modal-header theme-bg card" [attr.style]="primaryGradientModal">
        <h4 class="modal-title pull-left">Add Service</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-booking-tm-register-add-service (closeModal)="closingModal($event)" [externalService]="selectedService"
            (managedService)="reloadServices($event)"></app-booking-tm-register-add-service>
    </div>
</ng-template>


<ng-template #delete>
    <div class="modal-header theme-bg card" [attr.style]="primaryGradientModal">
        <h4 class="modal-title pull-left">Delete Service</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row clearfix">
            <div class="col-lg-12">
                <span aria-hidden="true">Are you sure you want to delete this registry?</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-round btn-default" (click)="modalRef.hide()">No</button>
        <button type="button" class="btn btn-round btn-primary" style="background-color: #6cb440;"
            (click)="deleteService()">Yes</button>
    </div>
</ng-template>