<app-header></app-header>
<div id="main-content">
    <div class="block-header">
        <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>Edit Booking Panel</h1>
                <span></span>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <a href="#" class="btn" [routerLink]="[backRoute]"><i class="fa fa-arrow-left"></i> Back
                    to bookings list...</a>
            </div>
        </div>
    </div>
    <div class="col-sm-12 modal-header card theme-bg gradient">
        <div class="row clearfix">
            <div class="col-lg-1 col-md-12 col-sm-12">
                <div class="circle">
                    <img class="rounded-circle" src="assets/images/{{getProfilePath(user.foto)}}" alt="Profile Photo"
                        *ngIf="getProfilePath(user.foto).length != 0">
                    <span *ngIf="getProfilePath(user.foto).length == 0"
                        class="rounded-circle empty-img theme-bg gradient text-center">
                        <h1>{{firstLetter(user.nombres)}}</h1>
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12" style="display: flex;align-items: center; padding-left: 10px;">
                <span><em>Assigned seller:</em><br>{{user.nombres}} {{user.apellidos}}<br>{{user.email}}</span>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">

            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 text-lg-right">
                <h1>Booking {{booking.codigo}}</h1>
                Status: <span
                    class="badge badge-{{badgeTypeSet(booking.statusCodigo)}}">{{statusCatalogClass(booking.statusCodigo)}}</span><br>
                Last update: Dec 12, 2024 | 15:25
            </div>
        </div>
    </div>
    <app-booking-form [booking]="booking" [formAction]="'edit'"></app-booking-form>
    <app-booking-detail-table [code]="bookingCode"></app-booking-detail-table>
</div>