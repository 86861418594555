<div class="row clearfix">
    <div class="col-sm-12">
        <div class="col-sm-12 form-group c_form_group">
            <div class="row">
                <div class="col-sm-4">
                    <div>
                        <small class="text-muted">Name:</small>
                        <p>{{booking.nombres}} {{booking.apellidos}}</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div>
                        <small class="text-muted">Email:</small>
                        <p style="word-break: break-all;white-space: normal;">{{booking.email}}</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div>
                        <small class="text-muted">Category:</small>
                        <p>{{booking.categoria}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div>
                        <small class="text-muted"># Pax:</small>
                        <p>{{booking.ninos+booking.adultos}}</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div>
                        <small class="text-muted">Source:</small>
                        <p style="word-break: break-all;white-space: normal;">{{booking.fuente}}</p>
                    </div>
                </div>
                <div class="col-sm-4">
                </div>
            </div>
        </div>
        <br />
        <div class="col-lg-8 col-md-12 col-sm-12">
            <span>Related bookings</span>
        </div>
        <div class="card">
            <div class="table-responsive">
                <table class="table table-striped custom-table">
                    <thead>
                        <tr>
                            <th>Booking</th>
                            <th>Name</th>
                            <th>Register Date</th>
                            <th>Source</th>
                            <th>Seller</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="searchingBooking" [attr.style]="primaryGradientModal">
                            <td colspan="9" style="text-align: center;color: white;">
                                <strong>Loading...</strong>
                                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                            </td>
                        </tr>
                        <tr *ngIf="totalBookingsHistory==0 && !searchingBooking" [attr.style]="primaryGradientModal">
                            <td style="text-align: center;color: white;" colspan="9">No bookings were found according to
                                the criteria, please try again.
                            </td>
                        </tr>
                        <tr *ngFor="let item of bookingsHistory | paginate: { id: 'nav-history', itemsPerPage: 5, currentPage: pageH, totalItems: totalBookingsHistory }"
                            [ngStyle]="{'display': searchingBooking ? 'none':''}">
                            <td>{{item.codigo}}</td>
                            <td>
                                <div>{{item.nombres}} {{item.apellidos}}</div>
                            </td>
                            <td>{{item.fechaRegistro}}</td>
                            <td>{{item.fuente}}</td>
                            <td>
                                <div class="circle">
                                    <img *ngIf="item.usuario!=null && getProfilePath(item.usuario.foto).length != 0"
                                        class="rounded-circle avatar" [src]="'assets/images/sellers/'+item.usuario.foto"
                                        alt="" title="{{item.usuario.nombres}} {{item.usuario.apellidos}}">
                                    <div class="rounded-circle empty-img" [attr.style]="primaryGradientModal"
                                        *ngIf="item.usuario!=null && getProfilePath(item.usuario.foto).length == 0"
                                        title="{{item.usuario.nombres}} {{item.usuario.apellidos}}">
                                        <span>
                                            {{firstLetter(item.usuario.nombres)}}
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card">
                <nav aria-label="Navigation history">
                    <pagination-controls id="nav-history" class="custom-pagination" (pageChange)="pageChanged($event)"
                        [responsive]="true" previousLabel="Prev">
                    </pagination-controls>
                </nav>
            </div>
        </div>
    </div>