<div class="col-sm-12 modal-header">
    <div class="row clearfix">
        <h4 class="modal-title pull-left">Contact Info</h4>
    </div>
</div>
<br>
<div class="col-sm-12">
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Name:</small>
                <p>{{booking.nombres}} {{booking.apellidos}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Email:</small>
                <p style="word-break: break-all;white-space: normal;">{{booking.email}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Country:</small>
                <p>{{booking.pais}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">City:</small>
                <p>{{booking.ciudad}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Phone:</small>
                <p>{{booking.telefono}}</p>
            </div>
        </div>
        <div class="col-sm-4">
        </div>
    </div>
</div>
<div class="col-sm-12 modal-header">
    <div class="row clearfix">
        <h4 class="modal-title pull-left">Trip Info</h4>
    </div>
</div>
<br>
<div class="col-sm-12">
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Service category:</small>
                <p>{{booking.categoria}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Trip Dates:</small>
                <p>{{fechaSalida}} - {{fechaRetorno}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Adults:</small>
                <p>{{booking.adultos}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Children:</small>
                <p>{{booking.ninos}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Boat Preference:</small>
                <p></p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Length of Trip:</small>
                <p>{{numberOfDays}}</p>
            </div>
        </div>
    </div>
</div>
<div class="col-sm-12 modal-header">
    <div class="row clearfix">
        <h4 class="modal-title pull-left">Other Info</h4>
    </div>
</div>
<br>
<div class="col-sm-12">
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Questions or Comments:</small>
                <p>{{booking.observacionesAsignacion}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Heard of Us:</small>
                <p></p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Heard Other:</small>
                <p></p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Language:</small>
                <p></p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Form Type:</small>
                <p>{{booking.fuente}}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Domain Site:</small>
                <p></p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Sender IP:</small>
                <p></p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Submitted on:</small>
                <p></p>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <small class="text-muted">Origin:</small>
                <p></p>
            </div>
        </div>
    </div>
</div>