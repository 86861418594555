import { Component, OnInit } from '@angular/core';
import { BookingModel } from '../../../../shared/models/booking.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from '../../../../shared/models/user.model';

@Component({
  selector: 'app-booking-add',
  templateUrl: './booking-add.component.html',
  styleUrl: './booking-add.component.scss'
})
export class BookingAddComponent {
  newBooking!: BookingModel;
  action!: string;
  userdata: any;
  ud:any;
  ccode:string="";
  ucode:string="";
  tokn:string="";

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);    if (this.userdata == null || this.userdata == undefined) {
      this.router.navigate(['']);
      sessionStorage.clear();
    } else {
      let todayPlus1 = new Date();
      todayPlus1.setDate(new Date().getDate()+1);
      let user!:UserModel;
      this.newBooking = {
        codigo: "",
        estado: 0,
        empresaCodigo: this.userdata.empresaCodigo,
        fuente: "0",
        status: null,
        statusCodigo:224,
        fechaRegistro: "",
        nombres: "",
        apellidos: "",
        email: "",
        telefono: "",
        categoria: "0",
        fechaSalida: new Date().getTime(),
        fechaRetorno: todayPlus1.getTime(),
        pais: 0,
        ciudad: 0,
        referencia: "",
        agencia: "",
        observacion: "",
        fechaAsignacion: "",
        usuarioCodigo: this.userdata.codigo,
        observacionesAsignacion: "",
        idOldSystem: 0,
        adultos:1,
        ninos:0,
        usuario: user
      }
      this.action = "add"
    }

  }
}
