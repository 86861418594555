import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { UserModel } from '../../../../shared/models/user.model';
import { UserService } from '../../../../shared/services/user.service';
import { retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-booking-assign',
  templateUrl: './booking-assign.component.html',
  styleUrl: './booking-assign.component.scss'
})
export class BookingAssignComponent {
  @Input() bookingId: string ="";
  @Input() email: string ="";
  @Output() closeModal = new EventEmitter<boolean>();

  users: UserModel[]=[];
  tokn:string="";
  ccode:string="";
  ucode:string="";
  backgroundButtonColor!: string;
  bookingAssignForm: FormGroup = new FormGroup({
  });

  constructor(
    private userService:UserService,
    private toastr:ToastrService,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.backgroundButtonColor = "background:" + (sessionStorage.getItem('modal-header-color') || (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || ''));
    this.userService.getAllUsersService(this.tokn)
      .pipe(retry(2))
      .subscribe(
        result => {
          this.users = result;
        },
        error => {
          this.toastr.error(error.message);
        });
  }

  
  submit(){
    this.closeModal.emit(true);
  }

  onReset(): void {
    this.closeModal.emit(true);
    //this.changeStatusForm.reset();
  }

}
