<form [formGroup]="changeStatusForm" (ngSubmit)="submitChangeStatus()">
    <div class="col-sm-12">
        <div class="row form-group">
            <div class="col-sm-4">
                <div class="form-group c_form_group">
                    <label>Status<span class="text-danger">*</span></label>
                    <select class="form-control" formControlName="status" [(ngModel)]="status"
                        [ngClass]="{ 'is-invalid': submitted && f['status'].errors }" (ngModelChange)="statusChange()"
                        required>
                        <ng-container *ngFor="let item of bookingStatusList">
                            <option [ngValue]="item.valor" [attr.disabled]="item.codigo<statusCode ? true:null"
                                [ngClass]="{ 'disabled-item':item.codigo<statusCode }">{{item.nombre}}
                            </option>
                        </ng-container>
                    </select>
                    <div *ngIf="submitted && f['status'].errors" class="invalid-feedback">
                        <div *ngIf="f['status'].errors['required']">Status
                            is required</div>
                    </div>
                </div>
                <div class="form-group c_form_group" *ngIf="status=='Muerto'">
                    <label>Remark<span class="text-danger">*</span></label>
                    <select class="form-control" formControlName="remark"
                        [ngClass]="{ 'is-invalid': submitted && f['remark'].errors }" required>
                        <option>All</option>
                        <option [ngValue]="item.valor" *ngFor="let item of remarkCatalog">{{item.nombre}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f['remark'].errors" class="invalid-feedback">
                        <div *ngIf="f['remark'].errors['required']">Status
                            is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="form-group c_form_group">
                    <label>Observations</label>
                    <div class="form-control">
                        <textarea class="form-control" value=""></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 text-lg-right">
        <button type="button" class="btn btn-default" (click)="onReset()">Cancel</button>
        <button type="submit" class="btn btn-primary" [attr.style]="backgroundButtonColor">Accept</button>
    </div>
</form>