import { Component, OnInit, Input } from '@angular/core';
import { CatalogModel } from '../../../../shared/models/catalog.model';
import { BookingModel } from '../../../../shared/models/booking.model';
import { ToastrService } from 'ngx-toastr';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { BookingService } from '../../../../shared/services/booking.service';
import { CatalogService } from '../../../../shared/services/catalog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryModel } from '../../../../shared/models/country.model';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent {
  companyList!: CatalogModel[];
  sourceList!: CatalogModel[];
  categoryList!: CatalogModel[];
  countriesList!: CountryModel[];
  citiesList!: CountryModel[];
  @Input() booking!: BookingModel;
  @Input() formAction!: string;
  @Input() isModal!: Boolean;
  bookingForm: FormGroup = new FormGroup({
    bookingName: new FormControl(),
    bookingLastName: new FormControl(),
    bookingEmail: new FormControl(),
    bookingServiceCategory: new FormControl(),
    bookingAdults: new FormControl(),
    bookingChildren: new FormControl(),
    bookingSource: new FormControl(),
    bookingCountry: new FormControl(),
    bookingCity: new FormControl(),
    bookingPhone: new FormControl()
  });
  isCheck!: boolean;
  submitted = false;
  arrivalDate: any;
  departureDate: any;
  ccode:string="";
  ucode:string="";
  tokn:string="";
  cm:any;
  so:any;
  ct:any;
  co:any;
  userdata: any;
  ud: any;
  backRoute: string = "";

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private catalogService: CatalogService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    public generalUtils: GeneralUtils
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    this.cm = sessionStorage.getItem('companyList')||(localStorage.getItem('companyList_' + this.ccode + '_' + this.ucode) || '');
    this.companyList = JSON.parse(this.cm);
    this.so = sessionStorage.getItem('sourceList')||(localStorage.getItem('sourceList_' + this.ccode + '_' + this.ucode) || '');
    this.sourceList = JSON.parse(this.so);
    this.ct = sessionStorage.getItem('categoryList')||(localStorage.getItem('categoryList_' + this.ccode + '_' + this.ucode) || '');
    this.categoryList = JSON.parse(this.ct);
    this.co = sessionStorage.getItem('countriesList')||(localStorage.getItem('countriesList_' + this.ccode + '_' + this.ucode) || '');
    this.countriesList = JSON.parse(this.co);
    this.isCheck = this.formAction == 'check';
    this.backRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/all-bookings';
    this.bookingForm = this.formBuilder.group(
      {
        bookingName: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
          ],
        ],
        bookingLastName: [
          '',
          [
            Validators.minLength(3),
            Validators.maxLength(30),
          ],
        ],
        bookingEmail: ['', [Validators.email]],
        bookingServiceCategory: ['', [Validators.required]],
        bookingAdults: ['',
          [
            Validators.required,
            Validators.min(1),
            Validators.max(20)
          ]
        ],
        bookingChildren: ['',
          [
            Validators.max(20)
          ]
        ],
        bookingCompany: ['', [Validators.required]],
        bookingSource: ['', [Validators.required]],
        bookingCountry: ['', [Validators.required]],
        bookingCity: ['', [Validators.required]],
        bookingPhone: ['', [Validators.required]]
      }
    );
  }

  ngAfterViewInit() {
    this.departureDate = new Date(this.booking.fechaRetorno);
    this.arrivalDate = new Date(this.booking.fechaSalida);
  }

  arrivalDateValueChange(e: any) {
    this.arrivalDate = new Date(e);
  }

  departureDateValueChange(e: any) {
    this.departureDate = new Date(e);
  }

  countryValueChange(e: any) {
    console.log(e);
    this.citiesList = [];
    this.catalogService.getCountryCities(this.tokn, e)
      .subscribe(
        result => {
          this.citiesList = result;
        },
        error => {
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.bookingForm.controls;
  }

  submit(formAction: string) {
    this.submitted = true;
    if (!this.bookingForm.invalid) {
      this.booking.fechaSalida = this.arrivalDate.getTime();
      this.booking.fechaRetorno = this.departureDate.getTime();
      console.log(this.booking);

      switch (formAction) {
        case "add": {
          this.bookingService.createBooking(this.tokn, this.booking)
            .subscribe(
              result => {
                this.toastr.success("Booking created succesfully.", "Success!");
                this.router.navigateByUrl(this.ccode+'/'+this.ucode+'/bookings/all-bookings');
              },
              error => {
                this.toastr.error("Please contact the system administrator", "There has been an error!");
              });
          break;
        }

        case "edit": {
          this.bookingService.updateBooking(this.tokn || '', this.booking)
            .subscribe(
              result => {
                this.toastr.success("Booking updated succesfully.", "Success!");
                this.router.navigateByUrl(this.ccode+'/'+this.ucode+'/bookings/all-bookings');
              },
              error => {
                this.toastr.error("Please contact the system administrator", "There has been an error!");
              });
          break
        }

        default:
          break;


      }
    } else {
      this.toastr.warning("Please check form warnings.", "Warning!");
    }

  }

  onReset(): void {
    this.submitted = false;
    this.router.navigate([this.backRoute]);

    //this.bookingForm.reset();
  }

}
