import { Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BookingModel } from '../../../../shared/models/booking.model';
import { CatalogModel } from '../../../../shared/models/catalog.model';
import { BookingService } from '../../../../shared/services/booking.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { BookingUtils } from '../../../../shared/utils/bookingUtils';
import { StatusCatalogEnum } from '../../../../shared/enums/status.catalog.enum';
import { ListSizeEnum } from '../../../../shared/enums/list.size.enum';
import { UtilImage } from '../../../../shared/utils/utilImage';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrl: './booking-list.component.scss'
})
export class BookingListComponent {
  modalRef!: BsModalRef;
  bookings!: BookingModel[];
  bookingStatusList!: CatalogModel[];
  booking!: BookingModel;
  page: number = 1;
  userdata: any;
  ud: any;
  searchTerm!: string;
  searchStatus!: string;
  searchDateFrom: string = "";
  searchDateTo: string = "";
  dateFromValue: string = "";
  dateToValue:string = "";
  minDateTo!:Date;
  closeModal: boolean = false;
  selectedBookingCode!: string;
  selectedBookingEmail!: string;
  totalBookings: number = 0;
  actualTotalBookings: number = 0;
  initialTotalBookings: number = 0;
  searchingBooking: boolean = false;
  primaryGradientModal!: string;
  pageSizes: number[] = [];
  pageSize = 10;
  ccode: string = "";
  ucode: string = "";
  editBookingRoute: string = "";
  detailBookingRoute: string = "";
  tmBookingRoute: string = "";
  tokn: string = "";
  urlStatus!: string;

  changeStatusForm: FormGroup = new FormGroup({
    status: new FormControl(),
    remark: new FormControl(),
    bsiName: new FormControl(),
    bsiNationality: new FormControl(),
    bsiAge: new FormControl(),
    bsiAdults: new FormControl(),
    bsiChildren: new FormControl(),
    bsiStartDate: new FormControl(),
    bsiEndDate: new FormControl()
  });

  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private bookingService: BookingService,
    private router: Router,
    private formBuilder: FormBuilder,
    private bookingUtils: BookingUtils,
    private activatedRoute: ActivatedRoute,
    private utilImage: UtilImage,
    public generalUtils: GeneralUtils
  ) {

  }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.primaryGradientModal = "--primary-color:" + (sessionStorage.getItem('modal-header-color') || (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || ''));
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    this.urlStatus = this.activatedRoute.snapshot.params['status'] || '';
    if (this.userdata.length == 0) {
      this.router.navigate(['']);
    } else {
      this.editBookingRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/edit-booking';
      this.detailBookingRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/booking-detail';
      this.tmBookingRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/booking-tm-register';
      this.bookingStatusList = JSON.parse(sessionStorage.getItem('bookingStatusList') || (localStorage.getItem('bookingStatusList_' + this.ccode + '_' + this.ucode) || ''));
      this.searchTerm = "";
      this.searchStatus = this.urlStatus == '' ? this.userdata.rol == 'ADMIN' ? 'Por Asignar' : 'All' : this.urlStatus;
      this.countBookings();
    }
  }

  openModal(template: TemplateRef<any>, bookingId: string, email: string): void {
    this.selectedBookingCode = bookingId;
    this.selectedBookingEmail = email;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' }),
    );
  }

  countBookings() {
    this.searchingBooking = true;
    this.bookingService.countBookings(this.tokn, this.searchTerm.length != 0 ? this.searchTerm : "%20", this.searchStatus, this.searchDateFrom.length == 0 ? "null" : this.searchDateFrom, this.searchDateTo.length == 0 ? "null" : this.searchDateTo)
      .subscribe(
        result => {
          this.totalBookings = result;
          this.initialTotalBookings = result > this.initialTotalBookings ? result : this.initialTotalBookings;
          this.buildPageSize(this.totalBookings);
          this.listBookings();
        },
        error => {
          this.searchingBooking = false;
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });

  }

  listBookings() {
    let page = this.page - 1;
    this.bookingService.listBookings(this.tokn, this.searchTerm.length != 0 ? this.searchTerm : "%20", this.searchStatus, this.searchDateFrom.length == 0 ? "null" : this.searchDateFrom, this.searchDateTo.length == 0 ? "null" : this.searchDateTo, page.toString(), this.pageSize.toString())
      .subscribe(
        result => {
          this.searchingBooking = false;
          this.bookings = result;
          this.actualTotalBookings = this.bookings.length;
        },
        error => {
          this.searchingBooking = false;
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
  }

  deleteBooking() {
    this.bookingService.deleteBooking(this.tokn, this.selectedBookingCode).subscribe(
      result => {
        this.modalRef.hide();
        this.toastr.success("Booking deleted succesfully.", "Success!");
        this.listBookings();
      },
      error => {
        this.toastr.error("Please contact the system administrator", "There has been an error!");
      });
  }

  closingModal(closeModal: boolean) {
    if (closeModal) {
      this.modalRef.hide();
    }
  }

  setBadgeType(badge: any): string {
    let badgeIndex = Object.keys(StatusCatalogEnum).indexOf(badge);
    let badgeCode = Number(Object.values(StatusCatalogEnum)[badgeIndex]);
    return this.bookingUtils.badgeTypeSet(badgeCode);
  }

  showCheckButton(status: any): boolean {
    return status !== 'Vendido' ? true : false;
  }

  buildPageSize(total: number) {
    this.pageSizes = [];
    let factor = total / ListSizeEnum.large > 5 ? 5 : total / ListSizeEnum.large;
    for (let i = 1; i <= factor; i++) {
      this.pageSizes.push(i * ListSizeEnum.large);
    }
  }

  pageChanged(e: any) {
    this.searchingBooking = true;
    this.page = e;
    this.listBookings();
  }

  itemsPerPageChanged() {
    this.searchingBooking = true;
    this.page = 1;
    this.listBookings();
  }

  getProfilePath(photValue: string | null): string {
    return this.utilImage.getProfileImagePath(photValue);
  }

  firstLetter(name: string): string {
    return name.substring(0, 1).toUpperCase();
  }

  sortBy(criteria: string) {
    console.log(criteria);
  }

  isForAssignment(state: any): boolean {
    return state == 'Por Asignar';
  }

  formatDateFrom(dateFrom: any) {
    this.minDateTo = dateFrom;
    this.searchDateFrom = this.generalUtils.formatDateForService(dateFrom);
    this.searchDateTo = this.generalUtils.formatDateForService(dateFrom);
    this.dateToValue = dateFrom;
  }

  formatDateTo(dateTo: any) {
    this.searchDateTo = this.generalUtils.formatDateForService(dateTo);
  }

  clearFilters(){
    this.searchTerm="";
    this.dateFromValue="";
    this.dateToValue="";
    this.searchDateFrom="";
    this.searchDateTo="";
    this.searchStatus = this.urlStatus == '' ? this.userdata.rol == 'ADMIN' ? 'Por Asignar' : 'All' : this.urlStatus;
    this.countBookings();
  }
}
