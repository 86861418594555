<app-header></app-header>
<div id="main-content">
    <div class="block-header">
        <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>Booking Detail Panel</h1>
                <span></span>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <a href="#" class="btn" [routerLink]="[backRoute]"><i class="fa fa-arrow-left"></i> Back to bookings list...</a>
            </div>
        </div>
    </div>
    <div class="col-sm-12 modal-header card theme-bg gradient">
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 text-lg-right">
                <h1>Booking {{bookingCode}}</h1>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="row clearfix">
            <div class="col-sm-4">
                <app-booking-check [isModal]="false" [eventReceptor]="externalBooking"></app-booking-check>
            </div>
            <div class="col-sm-8">
                <app-booking-detail-table [code]="bookingCode"></app-booking-detail-table>
                <div class="col-sm-12 modal-header">
                    <div class="row clearfix">
                        <h4 class="modal-title pull-left">Sell Info</h4>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="row clearfix">
                        <div class="col-sm-2">
                            <p class="text-muted">Start Date:</p>
                        </div>
                        <div class="col-sm-2">
                            <p>{{fechaSalida}}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="text-muted">End Date:</p>
                        </div>
                        <div class="col-sm-2">
                            <p>{{fechaRetorno}}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="text-muted">Nationality:</p>
                        </div>
                        <div class="col-sm-2">
                            <p></p>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-2">
                            <p class="text-muted">Age:</p>
                        </div>
                        <div class="col-sm-2">
                            <p></p>
                        </div>
                        <div class="col-sm-2">
                            <p class="text-muted">Adults:</p>
                        </div>
                        <div class="col-sm-2">
                            <p>{{booking.adultos}}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="text-muted">Childrens:</p>
                        </div>
                        <div class="col-sm-2">
                            <p>{{booking.ninos}}</p>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-2">
                            <p class="text-muted">Sale Type Ship:</p>
                        </div>
                        <div class="col-sm-2">
                            <p></p>
                        </div>
                        <div class="col-sm-2">
                            <p class="text-muted">Cruise Category:</p>
                        </div>
                        <div class="col-sm-2">
                            <p></p>
                        </div>
                        <div class="col-sm-2">
                            <p class="text-muted"># Days:</p>
                        </div>
                        <div class="col-sm-2">
                            <p>{{numberOfDays}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>