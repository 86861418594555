import { Component, OnInit, Input } from '@angular/core';
import { BookingService } from '../../../../shared/services/booking.service';
import { ToastrService } from 'ngx-toastr';
import { BookingModel } from '../../../../shared/models/booking.model';
import { ActivatedRoute } from '@angular/router';
import { GeneralUtils } from '../../../../shared/utils/generalUtils';
import { ListSizeEnum } from '../../../../shared/enums/list.size.enum';
import { UtilImage } from '../../../../shared/utils/utilImage';

@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrl: './booking-history.component.scss'
})
export class BookingHistoryComponent {
  bookingsHistory!: BookingModel[];
  @Input() bookingId!: string;
  @Input() email!: string;
  booking!: BookingModel;
  pageH: number = 1;
  totalBookingsHistory: number = 0;
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  pageSizesHistory: number[] = [];
  searchingBooking: boolean = false;
  primaryGradientModal!: string;

  constructor(
    private bookingService: BookingService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private generalUtils: GeneralUtils,
    private utilImage: UtilImage
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.primaryGradientModal = "background:" + (sessionStorage.getItem('modal-header-color') || (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || ''));
    if (!this.generalUtils.isNullOrUndefined(this.bookingId)) {
      this.bookingService.getBookingById(this.tokn, this.bookingId).subscribe(
        result => {
          this.booking = result;
          this.booking.adultos = this.generalUtils.isNullOrUndefined(this.booking.adultos) ? 1 : this.booking.adultos;
          this.booking.ninos = this.generalUtils.isNullOrUndefined(this.booking.ninos) ? 0 : this.booking.ninos;
          this.countBookingsH();
        },
        error => {
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
    } else {

    }
  }

  countBookingsH() {
    this.bookingService.countBookings(this.tokn, this.email, 'All', 'null', 'null' )
      .subscribe(
        result => {
          this.totalBookingsHistory = result;
          this.listBookingsH();
        },
        error => {
          this.searchingBooking = false;
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });

  }

  listBookingsH() {
    this.searchingBooking = true;

    let page = this.pageH - 1;
    this.bookingService.listBookings(this.tokn, this.email, 'All', 'null', 'null', page.toString(), '5')
      .subscribe(
        result => {
          this.searchingBooking = false;
          this.bookingsHistory = result;
        },
        error => {
          this.searchingBooking = false;
          this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
  }

  pageChanged(e: any) {
    this.searchingBooking = true;
    this.pageH = e;
    this.listBookingsH();
  }

  getProfilePath(photValue: string | null): string {
    return this.utilImage.getProfileImagePath(photValue);
  }

  firstLetter(name: string): string {
    return name.substring(0, 1).toUpperCase();
  }

}
