import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BookingModel } from '../models/booking.model';
import { BookingDetailModel } from '../models/booking-detail.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private url = "https://develop.api.tbs.galapagosislands.com/booking";
  private urltrack = "https://develop.api.tbs.galapagosislands.com/tracking";
  constructor(
    private http: HttpClient
  ) { }

  createBooking(tkn: string, booking: BookingModel): Observable<Boolean> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    return this.http.post<Boolean>(this.url, booking, httpOptions);
  }

  countBookings(tkn: string, term: string, status: string, dateFrom: string, dateTo: string): Observable<number> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    let url = this.url + "/count/" + term + "/" + status + "/" + dateFrom + "/" + dateTo;
    return this.http.get<number>(url, httpOptions);
  }

  listBookings(tkn: string, term: string, status: string, dateFrom: string, dateTo: string, page:string, size:string): Observable<BookingModel[]> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    let url = this.url + "/" + term + "/" + status + "/" + dateFrom + "/" + dateTo + "/" + page + "/" + size;
    return this.http.get<BookingModel[]>(url, httpOptions);
  }

  getBookingById(tkn: string, bookingId: string): Observable<BookingModel> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    let url = this.url + "/" + bookingId;
    return this.http.get<BookingModel>(url, httpOptions);
  }

  deleteBooking(tkn: string, bookingId: string): Observable<Boolean> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    let url = this.url + "/" + bookingId;
    return this.http.delete<Boolean>(url, httpOptions);
  }

  updateBooking(tkn: string, booking: BookingModel) {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    let url = this.url + "/" + booking.codigo;
    return this.http.put<Boolean>(url, booking, httpOptions);
  }

  getTrackingBookingDetails(tkn: string, bookingId: string): Observable<BookingDetailModel[]> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    let url = this.urltrack + "/booking/" + bookingId;
    return this.http.get<BookingDetailModel[]>(url, httpOptions);
  }

  headers(tkn: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tkn}`
    });
  }

}
