<form [formGroup]="bookingForm" (ngSubmit)="submit(formAction)">
    <div class="row clearfix">
        <div class="col-md-12" [ngClass]="formAction=='check' ? 'disableDiv':''">
            <div class="card">
                <div class="header">
                    <h2>Basic Information</h2>
                </div>
                <div class="body">
                    <div class="row clearfix">
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Name<span class="text-danger">*</span></label>
                                <input class="form-control" type="text" maxlength="45" formControlName="bookingName"
                                    [(ngModel)]="booking.nombres"
                                    [ngClass]="{ 'is-invalid': submitted && f['bookingName'].errors }" required>
                                <div *ngIf="submitted && f['bookingName'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingName'].errors['required']">Name is required</div>
                                    <div *ngIf="f['bookingName'].errors['minlength']">
                                        Name must be at least 3 characters
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Last name</label>
                                <input class="form-control" type="text" maxlength="45" formControlName="bookingLastName"
                                    [(ngModel)]="booking.apellidos"
                                    [ngClass]="{ 'is-invalid': submitted && f['bookingLastName'].errors }">
                                <div *ngIf="submitted && f['bookingLastName'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingLastName'].errors['minlength']">
                                        Last name must be at least 3 characters
                                    </div>
                                    <div *ngIf="f['bookingLastName'].errors['maxlength']">
                                        Last name must not exceed 15 characters
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>E-mail</label>
                                <input class="form-control" type="text" formControlName="bookingEmail"
                                    [(ngModel)]="booking.email"
                                    [ngClass]="{ 'is-invalid': submitted && f['bookingEmail'].errors }">
                                <div *ngIf="submitted && f['bookingEmail'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingEmail'].errors['email']">Email is invalid</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Arrival date</label>
                                <input type="text" [bsValue]="arrivalDate"
                                    (bsValueChange)="arrivalDateValueChange($event)" data-provide="datepicker"
                                    data-date-autoclose="true" class="form-control" placeholder=""
                                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}"
                                    bsDatepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Departure date</label>
                                <input type="text" [bsValue]="departureDate"
                                    (bsValueChange)="departureDateValueChange($event)" data-provide="datepicker"
                                    data-date-autoclose="true" class="form-control" placeholder=""
                                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: generalUtils.setCompanycolor(userdata.empresaCodigo)}"
                                    bsDatepicker>
                            </div>
                        </div>
                        <div class=" col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Service category <span class="text-danger">*</span></label>
                                <select class="form-control show-tick" formControlName="bookingServiceCategory"
                                    [(ngModel)]="booking.categoria"
                                    [ngClass]="{ 'is-invalid select-is-invalid': submitted && f['bookingServiceCategory'].errors }"
                                    required>
                                    <option disabled selected [ngValue]="'0'">-Select-</option>
                                    <option [ngValue]="item.valor" *ngFor="let item of categoryList">{{item.nombre}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['bookingServiceCategory'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingServiceCategory'].errors['required']">Select Service
                                        Category
                                        is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Adults <span class="text-danger">*</span></label>
                                <input class="form-control" type="number" min="1" max="20"
                                    formControlName="bookingAdults" [(ngModel)]="booking.adultos"
                                    [ngClass]="{ 'is-invalid': submitted && f['bookingAdults'].errors }" required>
                                <div *ngIf="submitted && f['bookingAdults'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingAdults'].errors['required']">Adults
                                        is required</div>
                                    <div *ngIf="f['bookingAdults'].errors['min']">Minimum value 1</div>
                                    <div *ngIf="f['bookingAdults'].errors['max']">Max value 20</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Children</label>
                                <input class="form-control" type="number" min="0" max="20"
                                    formControlName="bookingChildren" [(ngModel)]="booking.ninos"
                                    [ngClass]="{ 'is-invalid': submitted && f['bookingChildren'].errors }">
                                <div *ngIf="submitted && f['bookingChildren'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingChildren'].errors['max']">Max value 20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Company <span class="text-danger">*</span></label>
                                <select class="form-control show-tick" formControlName="bookingCompany"
                                    [(ngModel)]="booking.empresaCodigo" [ngClass]="'disableDiv'">
                                    <option disabled selected (value)="0">-Select-</option>
                                    <option [ngValue]="item.codigo" *ngFor="let item of companyList">{{item.nombre}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Source <span class="text-danger">*</span></label>
                                <select class="form-control show-tick" formControlName="bookingSource"
                                    [(ngModel)]="booking.fuente"
                                    [ngClass]="{ 'is-invalid select-is-invalid': submitted && f['bookingSource'].errors }"
                                    required>
                                    <option disabled selected [ngValue]="'0'">-Select-</option>
                                    <option [ngValue]="item.valor" *ngFor="let item of sourceList">{{item.nombre}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['bookingSource'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingSource'].errors['required']">Select Source is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Country <span class="text-danger">*</span></label>
                                <select class="form-control show-tick" formControlName="bookingCountry"
                                    [(ngModel)]="booking.pais" (ngModelChange)="countryValueChange(booking.pais)"
                                    [ngClass]="{ 'is-invalid select-is-invalid': submitted && f['bookingCountry'].errors }"
                                    required>
                                    <option disabled selected [ngValue]="0">-Select-</option>
                                    <option [ngValue]="item.codigo" *ngFor="let item of countriesList">
                                        {{item.nombre}}
                                </select>
                                <div *ngIf="submitted && f['bookingCountry'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingCountry'].errors['required']">Select Country is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>City <span class="text-danger">*</span></label>
                                <select class="form-control show-tick" formControlName="bookingCity"
                                    [(ngModel)]="booking.ciudad"
                                    [ngClass]="{ 'is-invalid select-is-invalid': submitted && f['bookingCity'].errors }"
                                    required>
                                    <option disabled selected [ngValue]="0">-Select-</option>
                                    <option [ngValue]="item.codigo" *ngFor="let item of citiesList">{{item.nombre}}
                                </select>
                                <div *ngIf="submitted && f['bookingCity'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingCity'].errors['required']">Select City is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>Phone <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" formControlName="bookingPhone"
                                    [(ngModel)]="booking.telefono"
                                    [ngClass]="{ 'is-invalid': submitted && f['bookingPhone'].errors }" required>
                                <div *ngIf="submitted && f['bookingServiceCategory'].errors" class="invalid-feedback">
                                    <div *ngIf="f['bookingServiceCategory'].errors['required']">Phone
                                        is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="form-group c_form_group">
                                <label>Observations</label>
                                <input class="form-control" type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header">
                    <h2>SEO Information</h2>
                </div>
                <div class="body">
                    <div class="row clearfix">
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Source </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Medium </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Campaign </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Term </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Adgroup </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Matchtype </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Content </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Ad </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Keyword </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Extension </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group c_form_group">
                                <label>UTM Placement </label>
                                <input class="form-control" type="text" value="">
                            </div>
                        </div>
                        <div class="col-sm-3">
                        </div>
                    </div>
                </div>
                <div class="body">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 text-lg-right">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <button class="btn btn-outline-secondary ml-1" (click)="onReset()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>



<!-- Bootstrap DatePicker -->
<script type="text/javascript">
    $(function () {
        $('#txtDate').datepicker({
            format: "dd/mm/yyyy"
        });
    });
</script>