import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CatalogModel } from '../../../../shared/models/catalog.model';
import { CountryModel } from '../../../../shared/models/country.model';
import { BookingService } from '../../../../shared/services/booking.service';
import { CatalogService } from '../../../../shared/services/catalog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { BookingModel } from '../../../../shared/models/booking.model';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-booking-track',
  templateUrl: './booking-track.component.html',
  styleUrl: './booking-track.component.scss'
})
export class BookingTrackComponent {
  @Input() bookingCode: any;
  @Output() closeModal = new EventEmitter<boolean>();

  status: any;
  statusCode: any;
  submitted = false;
  bookingStatusList!: CatalogModel[];
  bst:any;
  saleType: any;
  booking!: BookingModel;
  remarkCatalog!: CatalogModel[];
  rc:any;  
  countriesList!:CountryModel[];
  cn:any;
  categoryList!:CatalogModel[];
  ct:any;
  saleTypeList!:CatalogModel[];
  st:any;
  locationList!:CatalogModel[];
  lc:any;
  hotelLocationList!:CatalogModel[];
  ht:any;
  cruiseDaysList!:CatalogModel[];
  cd:any;
  tourDaysList!:CatalogModel[];
  td:any;
  hotelNightsList!:CatalogModel[];
  hn:any;
  shipList!:CatalogModel[];
  sh:any;
  ccode:string="";  
  ucode:string="";
  tokn:string="";
  backgroundButtonColor!: string;


  changeStatusForm: FormGroup = new FormGroup({
    status: new FormControl(),
    remark: new FormControl()
  });

  constructor(
    private bookingService: BookingService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private catalogService: CatalogService,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.bst = sessionStorage.getItem('bookingStatusList')||(localStorage.getItem('bookingStatusList_' + this.ccode + '_' + this.ucode) || '');
    this.bookingStatusList = JSON.parse(this.bst);
    this.cn = sessionStorage.getItem('countriesList')||(localStorage.getItem('countriesList_' + this.ccode + '_' + this.ucode) || '');
    this.countriesList = JSON.parse(this.cn);
    this.ct = sessionStorage.getItem('categoryList')||(localStorage.getItem('categoryList_' + this.ccode + '_' + this.ucode) || '');
    this.categoryList = JSON.parse(this.ct);
    this.st = sessionStorage.getItem('saleTypeList')||(localStorage.getItem('saleTypeList_' + this.ccode + '_' + this.ucode) || '');
    this.saleTypeList = JSON.parse(this.st);
    this.lc = sessionStorage.getItem('locationList')||(localStorage.getItem('locationList_' + this.ccode + '_' + this.ucode) || '');
    this.locationList = JSON.parse(this.lc);
    this.ht = sessionStorage.getItem('hotelLocationList')||(localStorage.getItem('hotelLocationList_' + this.ccode + '_' + this.ucode) || '');
    this.hotelLocationList = JSON.parse(this.ht);
    this.cd = sessionStorage.getItem('cruiseDaysList')||(localStorage.getItem('cruiseDaysList_' + this.ccode + '_' + this.ucode) || '');
    this.cruiseDaysList = JSON.parse(this.cd);
    this.td = sessionStorage.getItem('tourDaysList')||(localStorage.getItem('tourDaysList_' + this.ccode + '_' + this.ucode) || '');
    this.tourDaysList = JSON.parse(this.td);
    this.hn = sessionStorage.getItem('hotelNightsList')||(localStorage.getItem('hotelNightsList_' + this.ccode + '_' + this.ucode) || '');
    this.hotelNightsList = JSON.parse(this.hn);
    this.sh = sessionStorage.getItem('shipList')||(localStorage.getItem('shipList_' + this.ccode + '_' + this.ucode) || '');
    this.shipList = JSON.parse(this.sh);
    this.backgroundButtonColor = "background:" + (sessionStorage.getItem('modal-header-color') || (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || ''));
    this.bookingService.getBookingById(this.tokn, this.bookingCode).pipe(retry(2)).subscribe(
      result => {
        this.booking = result;
        this.bookingStatusList.forEach((item) => {
          if (item.codigo == this.booking.statusCodigo) {
            this.status = item.valor;
            this.statusCode = item.codigo;
          }
        });
        this.getRemarkCatalog();
      },
      error => {
        this.toastr.error("Please contact the system administrator", "There has been an error!");
      });
  }

  submitChangeStatus() {
    this.submitted = true;
    if (!this.changeStatusForm.invalid) {
      this.closeModal.emit(true);
      this.toastr.success("Status changed succesfully.", "Success!");
    } else {
      this.toastr.warning("Please check form warnings.", "Warning!");
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.changeStatusForm.controls;
  }

  statusChange() {
    this.submitted = false;
    for (let name in this.changeStatusForm.controls) {
      if (name != 'status') {
        this.changeStatusForm.controls[name].reset();
        this.changeStatusForm.controls[name].setErrors(null);
      }
    }

    switch (this.status) {
      case 'Muerto': {
        this.changeStatusForm.get('remark')!.setValidators(Validators.required);
        break;
      }

      default: {
        break;
      }

    }
  }

  saleTypeChange() {
    //this.submitted = false;
    this.changeStatusForm.controls['cruiseShip'].reset();
    this.changeStatusForm.controls['cruiseShip'].setErrors(null);
    this.changeStatusForm.controls['cruiseCategory'].reset();
    this.changeStatusForm.controls['cruiseCategory'].setErrors(null);
    this.changeStatusForm.controls['cruiseDays'].reset();
    this.changeStatusForm.controls['cruiseDays'].setErrors(null);
    this.changeStatusForm.controls['lbtLocation'].reset();
    this.changeStatusForm.controls['lbtLocation'].setErrors(null);
    this.changeStatusForm.controls['lbtCategory'].reset();
    this.changeStatusForm.controls['lbtCategory'].setErrors(null);
    this.changeStatusForm.controls['lbtDays'].reset();
    this.changeStatusForm.controls['lbtDays'].setErrors(null);
    this.changeStatusForm.controls['hotelLocation'].reset();
    this.changeStatusForm.controls['hotelLocation'].setErrors(null);
    this.changeStatusForm.controls['hotelNights'].reset();
    this.changeStatusForm.controls['hotelNights'].setErrors(null);

    switch (this.saleType) {
      case 'cruise': {
        this.changeStatusForm.get('cruiseShip')!.setValidators(Validators.required);
        this.changeStatusForm.get('cruiseCategory')!.setValidators(Validators.required);
        this.changeStatusForm.get('cruiseDays')!.setValidators(Validators.required);
        break;
      }

      case 'lbt': {
        this.changeStatusForm.get('lbtLocation')!.setValidators(Validators.required);
        this.changeStatusForm.get('lbtCategory')!.setValidators(Validators.required);
        this.changeStatusForm.get('lbtDays')!.setValidators(Validators.required);
        break;
      }

      case 'hotel': {
        this.changeStatusForm.get('hotelLocation')!.setValidators(Validators.required);
        this.changeStatusForm.get('hotelNights')!.setValidators(Validators.required);
        break;
      }

      case 'cruise-lbt': {
        this.changeStatusForm.get('cruiseShip')!.setValidators(Validators.required);
        this.changeStatusForm.get('cruiseCategory')!.setValidators(Validators.required);
        this.changeStatusForm.get('cruiseDays')!.setValidators(Validators.required);
        this.changeStatusForm.get('lbtLocation')!.setValidators(Validators.required);
        this.changeStatusForm.get('lbtCategory')!.setValidators(Validators.required);
        this.changeStatusForm.get('lbtDays')!.setValidators(Validators.required);
        break;
      }

      default: {
        break;
      }

    }
  }

  getRemarkCatalog(): void {
    this.catalogService.getCatalog(this.tokn, 'OPCION_MUERTO', '0')
      .subscribe(
        result => {
          this.remarkCatalog = result;
        },
        error => {
          /*this.router.navigate(['']);
          sessionStorage.clear();*/
        });
  }

  onReset(): void {
    this.submitted = false;
    this.closeModal.emit(true);
    //this.changeStatusForm.reset();
  }

}
