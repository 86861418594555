import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookingService } from '../../../../../../shared/services/booking.service';
import { ToastrService } from 'ngx-toastr';
import { BookingDetailModel } from '../../../../../../shared/models/booking-detail.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-booking-detail-table',
  templateUrl: './booking-detail-table.component.html',
  styleUrl: './booking-detail-table.component.scss'
})
export class BookingDetailTableComponent {
  @Input() eventReceptor!: EventEmitter<string>;
  @Input() code!: string;
  bookingDetails!: BookingDetailModel[];
  page: number = 1;
  totalBookingDetails:number=0;
  ccode:string="";
  ucode:string="";
  tokn:string="";

  constructor(
    private bookingService: BookingService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.code + '_' + this.ucode) || '');
    this.bookingService.getTrackingBookingDetails(this.tokn, this.code).subscribe(
      result => {
        this.bookingDetails = result;
        this.totalBookingDetails = this.bookingDetails.length;
      },
      error => {
        this.toastr.error("Please contact the system administrator", "There has been an error!");
      });
  }
}
